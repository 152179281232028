



















































import { RequestModel } from '@/models/Request.model';
import requestsRepository from '@/repository/modules/requests.repository';
import { Component, Vue, Watch } from 'vue-property-decorator';
import TableRowRequest from '@/components/Requests/table-row-request.vue';

import { setterDate } from '@/plugins/utils';

@Component({
  components: {
    TableRowRequest,
  },
})
export default class ListRequests extends Vue {
  filter_type_requests = 0;
  filter_state_requests = 0;
  page = 1;
  limit = 100;
  total_pages = 1;
  total_results = 0;
  loading = true;
  listRequests: RequestModel[] = [];

  async mounted() {
    this.loadRequests();
  }

  async loadRequests() {
    this.loading = true;
    const { requests, total_results, total_pages } = await requestsRepository.getListRequests({
      query: {
        request_type: this.FilterRequestType,
        request_state: this.FilterRequestState,
      },
      page: this.page,
      limit: this.limit,
    });
    this.listRequests = requests;
    this.total_results = total_results ?? 0;
    this.total_pages = total_pages;
    this.loading = false;
  }

  get FilterRequestType() {
    if (this.filter_type_requests == 1) return 'nota-simple';
    else if (this.filter_type_requests == 2) return 'registro-propiedades';
    return undefined;
  }
  get FilterRequestState() {
    if (this.filter_state_requests == 1) return 'waiting-response-user';
    else if (this.filter_state_requests == 2) return 'waiting-response-registro';
    else if (this.filter_state_requests == 3) return 'finished';
    return 'in-process';
  }

  @Watch('FilterRequestType')
  changedFilterType() {
    this.loadRequests();
  }

  @Watch('FilterRequestState')
  changedFilterState() {
    this.loadRequests();
  }

  setterDate = setterDate;
}
